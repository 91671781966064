export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98')
];

export const server_loads = [2,17];

export const dictionary = {
		"/(app)": [18,[2],[3]],
		"/(app)/app_invites": [38,[2],[3]],
		"/(app)/avatars-collage/[id]": [39,[2,12],[3]],
		"/(app)/a/[id]": [37,[2,11],[3]],
		"/(app)/beta/signup": [40,[2],[3]],
		"/(app)/cloudflare-error": [41,[2],[3]],
		"/(app)/contest": [42,[2],[3]],
		"/(app)/creators": [43,[2],[3]],
		"/(app)/dashboard": [44,[2],[3]],
		"/(app)/discover": [45,[2],[3]],
		"/(app)/discover/[slug]": [46,[2],[3]],
		"/(nolayout)/editorial-collective": [97,[17]],
		"/(app)/events": [47,[2],[3]],
		"/(app)/faq": [48,[2],[3]],
		"/(app)/faq/creators": [49,[2],[3]],
		"/(app)/faq/floral-designers": [50,[2],[3]],
		"/(app)/faq/interior-designers": [51,[2],[3]],
		"/(app)/faq/photographers": [52,[2],[3]],
		"/(app)/faq/planners": [53,[2],[3]],
		"/(app)/faq/venues": [54,[2],[3]],
		"/(app)/featured": [55,[2],[3]],
		"/(app)/featured/[slug]/cover-photos-html": [56,[2,13],[3]],
		"/(app)/floral-designers": [57,[2],[3]],
		"/(app)/forgot_password": [58,[2],[3]],
		"/(app)/forgot_password/[token]": [59,[2],[3]],
		"/(app)/get-started": [60,[2],[3]],
		"/(app)/get-started/faq": [61,[2],[3]],
		"/(app)/global_search": [62,[2],[3]],
		"/(app)/home": [63,[2],[3]],
		"/(nolayout)/import": [98,[17]],
		"/(app)/import/[id]": [65,[2],[3]],
		"/(app)/interior-designers": [66,[2],[3]],
		"/(app)/i/[id]": [64,[2],[3]],
		"/(app)/login": [67,[2],[3]],
		"/(app)/magic_link": [68,[2],[3]],
		"/(app)/not-found": [69,[2],[3,14]],
		"/(app)/old_browser": [70,[2],[3]],
		"/(app)/photographers": [71,[2],[3]],
		"/(app)/planners": [72,[2],[3]],
		"/(app)/planners/tbs": [73,[2],[3]],
		"/(app)/privacy": [74,[2],[3]],
		"/(app)/profile-collage/[id]": [75,[2,15],[3]],
		"/(app)/profiles/account_settings": [77,[2],[3]],
		"/(app)/profiles/advanced_settings": [78,[2],[3]],
		"/(app)/profiles/blocked_users": [79,[2],[3]],
		"/(app)/profiles/cashback": [80,[2],[3]],
		"/(app)/profiles/change_email": [81,[2],[3]],
		"/(app)/profiles/change_password": [82,[2],[3]],
		"/(app)/profiles/delete_account": [83,[2],[3]],
		"/(app)/profiles/edit": [84,[2],[3]],
		"/(app)/profiles/list_organizer": [85,[2],[3]],
		"/(app)/profiles/notification_settings": [86,[2],[3]],
		"/(app)/profiles/[id]": [76,[2],[3]],
		"/(app)/q/[id]": [87,[2,16],[3]],
		"/(app)/rr/[token]": [88,[2],[3]],
		"/(app)/search": [89,[2],[3]],
		"/(app)/signup": [90,[2],[3]],
		"/(app)/sitemap.xml": [91,[2],[3]],
		"/(app)/sitemap[count].xml": [92,[2],[3]],
		"/(app)/venues": [93,[2],[3]],
		"/(app)/wipa": [95,[2],[3]],
		"/(app)/wrapped/[id]": [96,[2],[3]],
		"/(app)/w/[id]": [94,[2],[3]],
		"/(app)/[Tt]ik[Tt]ok": [19,[2],[3]],
		"/(app)/[id]": [20,[2],[3,4]],
		"/(app)/[id]/bookmarks": [21,[2],[3,4]],
		"/(app)/[username_or_id]/email-profile": [32,[2,7],[3]],
		"/(app)/[username_or_id]/featured-profile": [33,[2,8],[3]],
		"/(app)/[id]/followers": [22,[2],[3,4]],
		"/(app)/[id]/following": [23,[2],[3,4]],
		"/(app)/[username_or_id]/index-page": [34,[2,9],[3]],
		"/(app)/[username_or_id]/notifications": [35,[2],[3]],
		"/(app)/[id]/search": [24,[2],[3,4]],
		"/(app)/[username_or_id]/verified-profile": [36,[2,10],[3]],
		"/(app)/[username_or_id]/[slug]": [25,[2],[3,5]],
		"/(app)/[username_or_id]/[slug]/cover-photos-html": [26,[2,,6],[3,5]],
		"/(app)/[username_or_id]/[slug]/details": [27,[2],[3,5]],
		"/(app)/[username_or_id]/[slug]/edit": [28,[2],[3,5]],
		"/(app)/[username_or_id]/[slug]/invites/accept": [29,[2],[3,5]],
		"/(app)/[username_or_id]/[slug]/settings/advanced_settings": [30,[2],[3,5]],
		"/(app)/[username_or_id]/[slug]/settings/privacy": [31,[2],[3,5]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';